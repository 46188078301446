import React, { useEffect } from "react"

import view from "./modal.module.scss"

export default function VideoModal(props) {
  const { visible, closeModal, responsiveFluid } = props

  useEffect(() => {
    visible && (document.body.classList.add("modal-open"))
    return () => visible && (document.body.classList.remove("modal-open"))
  }, [visible]);

  return (
    visible ? (
      <div className={view["modal"]} onClick={closeModal}>
        <div className={`${view["modal-wrap"]} ${responsiveFluid && view["fluid"]}`}
         onClick={e => e.stopPropagation()}>
          <div className={view["modal-head"]}>
            <p className={view["modal-close"]} style={{ marginLeft: "1rem" }}>
              { props.title }
            </p>
            <p className={view["modal-close"]} onClick={closeModal}
              style={{ cursor: "pointer" }}
            >
              &#10006;
            </p>
          </div>
          <div className={view["modal-cont"]}>
            {props.children}
          </div>
        </div>
      </div>
    ) : (<></>)
  )
}

VideoModal.defaultProps = {
  visible: true,
  closeModal: () => { }
}
