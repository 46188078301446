import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

import VideoModal from "../components/modal/VideoModal"

export default function useModal(props) {
  const location = useLocation()

  const [modalVisible, toggleModal] = useState(false)

  function removeUrlParameter(paramKey) {
    const url = window.location.href
    var r = new URL(url)
    const beforeURL = r.href
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    if (beforeURL !== newUrl) {
      if (window.history && window.history.replaceState) {
        window.history.replaceState({ path: newUrl }, '', newUrl);
      }
    }
  }

  const renderModal = (children) => (
    <VideoModal visible={modalVisible} 
      title={props.title} closeModal={closeModal}
      responsiveFluid={props.responsiveFluid}
    >
      {children}
    </VideoModal>
  )

  const openModal = () => {
    toggleModal(true)
  }
  
  const closeModal = () => {
    window.location.hash = "_"
    toggleModal(false)
    removeUrlParameter('notes')
    removeUrlParameter('button')
    removeUrlParameter('formType')
    removeUrlParameter('comments')
    removeUrlParameter('title')
  }

  useEffect(() => {
    if (!location.hash && !props.location) 
      return

    if (location.hash === props.location) {
      toggleModal(true)
    } else {
      toggleModal(false)
    }
  }, [location.hash])


  return [renderModal, openModal, closeModal]
}

