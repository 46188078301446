import React, { useState } from "react"

const initial = {
  context: {
    finalPrice: 0,
    vehicleInfo: {}
  },
  setContext: () => { }
}

const ModalVehicleContext = React.createContext(initial)

function ModalVehicleProvider(props) {
  const [context, setContext] = useState(initial.context)

  return (
    <ModalVehicleContext.Provider value={[ context, setContext ]}>
      { props.children }
    </ModalVehicleContext.Provider>
  )
}

export { ModalVehicleContext, ModalVehicleProvider }